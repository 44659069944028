/*
**    Created by: Jeff Todnem (http://www.todnem.com/)http://www.passwordmeter.com/
**    Created on: 2007-08-14
**    Last modified: 2010-05-17 Kieron Matthews - Rocktime
** 
**    License Information:
**    -------------------------------------------------------------------------
**    Copyright (C) 2007 Jeff Todnem
**
**    This program is free software; you can redistribute it and/or modify it
**    under the terms of the GNU General Public License as published by the
**    Free Software Foundation; either version 2 of the License, or (at your
**    option) any later version.
**    
**    This program is distributed in the hope that it will be useful, but
**    WITHOUT ANY WARRANTY; without even the implied warranty of
**    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU
**    General Public License for more details.
**    
**    You should have received a copy of the GNU General Public License along
**    with this program; if not, write to the Free Software Foundation, Inc.,
**    59 Temple Place, Suite 330, Boston, MA 02111-1307 USA
**    
*/

String.prototype.strReverse = function () {
    var newstring = "";
    for (var s = 0; s < this.length; s++) {
        newstring = this.charAt(s) + newstring;
    }
    return newstring;
};

function pass_createError(value, index, pass_Standards, pass_Mins) {
    var output = '<li>' + pass_Standards[index] + '</li>';
    output = output.replace(/\{0\}/gi, pass_Mins[index])
    output = output.replace(/\{1\}/gi, value)
    output = output.replace('/', ' / ')
    return output
}

function pass_getBackgroundcolor(score) {
    var hexc = new Array('0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F');
    var redcol_1;
    var redcol_2;
    var greencol_1;
    var greencol_2;
    var bluecol_1;
    var bluecol_2;
    var red = 0;
    var green = 0;
    var blue = 0;

    if (score >= 50) {
        red = Math.round(255 - ((score - 50) * (255 / 50)));
    } else {
        red = 255;
    }

    if (score <= 50) {
        green = Math.round(255 - ((50 - score) * (255 / 50)));
    } else {
        green = 255;
    }

    redcol_1 = hexc[Math.floor(red / 16)];
    redcol_2 = hexc[red % 16];

    greencol_1 = hexc[Math.floor(green / 16)];
    greencol_2 = hexc[green % 16];

    bluecol_1 = hexc[Math.floor(blue / 16)];
    bluecol_2 = hexc[blue % 16];

    return '#' + redcol_1 + redcol_2 + greencol_1 + greencol_2 + bluecol_1 + bluecol_2
}

function pass_chkPassKeyPress(pwd, id) {
    var val = document.getElementById(id);
    val.isvalid = pass_chkPass(pwd, id);
    ValidatorUpdateDisplay(val);
    if (val.parent != undefined && val.parent != '') {
        val.parentObj = document.all ? document.all[val.parent] : document.getElementById(val.parent)
        RocktimeValidateEx(val.parentObj);
    };
}

function pass_chkPass(pwd, id) {

    var oHelp = $('#pass_help_' + id);
    var oScore = $('#pass_score_' + id);

    if (pwd == '') { oScore.hide(); oHelp.hide(); return true; }
    oScore.show();
    oHelp.show();

    var pass_DisplayType = eval('pass_DisplayType_' + id);
    var pass_UseHelp = eval('pass_UseHelp_' + id);
    var pass_ShowOk = eval('pass_ShowOk_' + id);
    var pass_Standards = eval('pass_Standards_' + id);
    var pass_Complexities = eval('pass_Complexities_' + id);
    var pass_ComplexityThresholds = eval('pass_ComplexityThresholds_' + id);
    var pass_Mins = eval('pass_Mins_' + id);

    // Simultaneous variable declaration and value assignment aren't supported in IE apparently
    // so I'm forced to assign the same value individually per var to support a crappy browser *sigh* 
    var nScore = 0, nLength = 0, nAlphaUC = 0, nAlphaLC = 0, nNumber = 0, nSymbol = 0, nMidChar = 0, nUnqChar = 0, nRepChar = 0, nRepInc = 0, nConsecAlphaUC = 0, nConsecAlphaLC = 0, nConsecNumber = 0, nConsecSymbol = 0, nConsecCharType = 0, nSeqAlpha = 0, nSeqNumber = 0, nSeqSymbol = 0, nSeqChar = 0, nReqChar = 0, nMultConsecCharType = 0;
    var nMultMidChar = 2, nMultConsecAlphaUC = 2, nMultConsecAlphaLC = 2, nMultConsecNumber = 2;
    var nMultSeqAlpha = 3, nMultSeqNumber = 3, nMultSeqSymbol = 3;
    var nMultLength = 4, nMultNumber = 4;
    var nMultSymbol = 6;
    var nTmpAlphaUC = "", nTmpAlphaLC = "", nTmpNumber = "", nTmpSymbol = "";
    var sAlphas = "abcdefghijklmnopqrstuvwxyzqwertyuiopasdfghjklzxcvbnm";
    var sNumerics = "01234567890";
    var sSymbols = ")!@#$%^&*()!\"�$%";

    var sComplexity = "";
    var sStandard = "";

    //if (document.all) { var nd = 0; } else { var nd = 1; }

    if (!pwd) {
        /* Display default score criteria to client */
        oScore.css({ 'backgroundColor': pass_getBackgroundcolor(0) });
        switch (pass_DisplayType) {
            case 0:
                oScore.text(pass_Complexities[0] + ' (0%)');
                break;

            case 1:
                oScore.text(pass_Complexities[0]);
                break;

            case 2:
                oScore.text('0%');
                break;
            case 3:
                oScore.text('&nbsp;');
                break;
        }
        if (pass_UseHelp) { oHelp.html(""); }
        return false;
    }


    nScore = parseInt(pwd.length * nMultLength);
    nLength = pwd.length;
    var arrPwd = pwd.replace(/\s+/g, "").split(/\s*/);
    var arrPwdLen = arrPwd.length;

    /* Loop through password to check for Symbol, Numeric, Lowercase and Uppercase pattern matches */
    for (var a = 0; a < arrPwdLen; a++) {
        if (arrPwd[a].match(/[A-Z]/g)) {
            if (nTmpAlphaUC !== "") { if ((nTmpAlphaUC + 1) == a) { nConsecAlphaUC++; nConsecCharType++; } }
            nTmpAlphaUC = a;
            nAlphaUC++;
        }
        else if (arrPwd[a].match(/[a-z]/g)) {
            if (nTmpAlphaLC !== "") { if ((nTmpAlphaLC + 1) == a) { nConsecAlphaLC++; nConsecCharType++; } }
            nTmpAlphaLC = a;
            nAlphaLC++;
        }
        else if (arrPwd[a].match(/[0-9]/g)) {
            if (a > 0 && a < (arrPwdLen - 1)) { nMidChar++; }
            if (nTmpNumber !== "") { if ((nTmpNumber + 1) == a) { nConsecNumber++; nConsecCharType++; } }
            nTmpNumber = a;
            nNumber++;
        }
        //else if (arrPwd[a].match(/[^a-zA-Z0-9_]/g)) {
        else {
            if (a > 0 && a < (arrPwdLen - 1)) { nMidChar++; }
            if (nTmpSymbol !== "") { if ((nTmpSymbol + 1) == a) { nConsecSymbol++; nConsecCharType++; } }
            nTmpSymbol = a;
            nSymbol++;
        }
        /* Internal loop through password to check for repeat characters */
        var bCharExists = false;
        for (var b = 0; b < arrPwdLen; b++) {
            if (arrPwd[a] == arrPwd[b] && a != b) { /* repeat character exists */
                bCharExists = true;
                /* 
                Calculate increment deduction based on proximity to identical characters
                Deduction is incremented each time a new match is discovered
                Deduction amount is based on total password length divided by the
                difference of distance between currently selected match
                */
                nRepInc += Math.abs(arrPwdLen / (b - a));
            }
        }
        if (bCharExists) {
            nRepChar++;
            nUnqChar = arrPwdLen - nRepChar;
            nRepInc = (nUnqChar) ? Math.ceil(nRepInc / nUnqChar) : Math.ceil(nRepInc);
        }
    }

    /* Check for sequential alpha string patterns (forward and reverse) */
    for (var s = 0; s < 49; s++) {
        var sFwd = sAlphas.substring(s, parseInt(s + 3));
        var sRev = sFwd.strReverse();
        if (pwd.toLowerCase().indexOf(sFwd) != -1 || pwd.toLowerCase().indexOf(sRev) != -1) { nSeqAlpha++; nSeqChar++; }
    }

    /* Check for sequential numeric string patterns (forward and reverse) */
    for (var s = 0; s < 8; s++) {
        var sFwd = sNumerics.substring(s, parseInt(s + 3));
        var sRev = sFwd.strReverse();
        if (pwd.toLowerCase().indexOf(sFwd) != -1 || pwd.toLowerCase().indexOf(sRev) != -1) { nSeqNumber++; nSeqChar++; }
    }

    /* Check for sequential symbol string patterns (forward and reverse) */
    for (var s = 0; s < 13; s++) {
        var sFwd = sSymbols.substring(s, parseInt(s + 3));
        var sRev = sFwd.strReverse();
        if (pwd.toLowerCase().indexOf(sFwd) != -1 || pwd.toLowerCase().indexOf(sRev) != -1) { nSeqSymbol++; nSeqChar++; }
    }

    /* Modify overall score value based on usage vs requirements */
    var nTypes = 0;

    /* General point assignment */
    if (nAlphaUC > 0) {
        nScore = parseInt(nScore + ((nLength - nAlphaUC) * 2));
        nTypes += 1;
    }
    if (nAlphaLC > 0) {
        nScore = parseInt(nScore + ((nLength - nAlphaLC) * 2));
        nTypes += 1;
    }
    if (nNumber > 0) {
        nScore = parseInt(nScore + (nNumber * nMultNumber));
        nTypes += 1;
    }
    if (nSymbol > 0) {
        nScore = parseInt(nScore + (nSymbol * nMultSymbol));
        nTypes += 1;
    }
    if (nMidChar > 0) {
        nScore = parseInt(nScore + (nMidChar * nMultMidChar));
    }

    /* Point deductions for poor practices */
    if ((nAlphaLC > 0 || nAlphaUC > 0) && nSymbol === 0 && nNumber === 0) {  // Only Letters
        nScore = parseInt(nScore - nLength);
    }
    if (nAlphaLC === 0 && nAlphaUC === 0 && nSymbol === 0 && nNumber > 0) {  // Only Numbers
        nScore = parseInt(nScore - nLength);
    }
    if (nRepChar > 0) {  // Same character exists more than once
        nScore = parseInt(nScore - nRepInc);
    }
    if (nConsecAlphaUC > 0) {  // Consecutive Uppercase Letters exist
        nScore = parseInt(nScore - (nConsecAlphaUC * nMultConsecAlphaUC));
    }
    if (nConsecAlphaLC > 0) {  // Consecutive Lowercase Letters exist
        nScore = parseInt(nScore - (nConsecAlphaLC * nMultConsecAlphaLC));
    }
    if (nConsecNumber > 0) {  // Consecutive Numbers exist
        nScore = parseInt(nScore - (nConsecNumber * nMultConsecNumber));
    }
    if (nSeqAlpha > 0) {  // Sequential alpha strings exist (3 characters or more)
        nScore = parseInt(nScore - (nSeqAlpha * nMultSeqAlpha));
    }
    if (nSeqNumber > 0) {  // Sequential numeric strings exist (3 characters or more)
        nScore = parseInt(nScore - (nSeqNumber * nMultSeqNumber));
    }
    if (nSeqSymbol > 0) {  // Sequential symbol strings exist (3 characters or more)
        nScore = parseInt(nScore - (nSeqSymbol * nMultSeqSymbol));
    }

    sStandard = "";
    //pass_Standards (0"length", 1"score", 2"upper case chars", 3"lower case chars", 4"numbers", 5"symbols", 6"%", 7"Ok", 8"You need to meet the following minimums:",);

    var penaltyOffset = pass_Mins[0] + pass_Mins[2] + pass_Mins[3] + pass_Mins[4] + pass_Mins[5];
    if (!penaltyOffset == 0) { penaltyOffset = 40 / penaltyOffset; }
    if (nScore > 100) { nScore = 100; } //ensures you can never get 100% if all criteria aren't met


    if (nLength < pass_Mins[0]) {
        nScore -= (penaltyOffset * (pass_Mins[0] - nLength));
        sStandard += pass_createError(nLength, 0, pass_Standards, pass_Mins);
    }

    if (nAlphaUC < pass_Mins[2]) {
        nScore -= (penaltyOffset * (pass_Mins[2] - nAlphaUC));
        sStandard += pass_createError(nAlphaUC, 2, pass_Standards, pass_Mins);
    }

    if (nAlphaLC < pass_Mins[3]) {
        nScore -= (penaltyOffset * (pass_Mins[3] - nAlphaLC));
        sStandard += pass_createError(nAlphaLC, 3, pass_Standards, pass_Mins);
    }

    if (nNumber < pass_Mins[4]) {
        nScore -= (penaltyOffset * (pass_Mins[4] - nNumber));
        sStandard += pass_createError(nNumber, 4, pass_Standards, pass_Mins);
    }

    if (nSymbol < pass_Mins[5]) {
        nScore -= (penaltyOffset * (pass_Mins[5] - nSymbol));
        sStandard += pass_createError(nSymbol, 5, pass_Standards, pass_Mins);
    }

    if (nTypes < pass_Mins[6]) {
        nScore -= (10 * (pass_Mins[6] - nTypes));
        sStandard += pass_createError(nTypes, 6, pass_Standards, pass_Mins);
    }

    nScore = Math.round(nScore);

    if (nScore > 100) { nScore = 100; } else if (nScore < 0) { nScore = 0; }
    if (nScore < pass_Mins[1]) {
        sStandard += pass_createError(nScore, 1, pass_Standards, pass_Mins);
    }

    var isValid;
    if (sStandard == "") {
        if (pass_ShowOk == 1) {
            sStandard = '<span>' + pass_Standards[7] + '</span>';
        } else {
            sStandard = '';
        }
        isValid = true;
    } else {
        sStandard = '<span>' + pass_Standards[8] + '</span><ul>' + sStandard + '</ul>'
        isValid = false;
    }

    //for color tesing
    //nScore = nLength * 1;

    /* Determine complexity based on overall score */
    if (nScore >= 0 && nScore < pass_ComplexityThresholds[0]) { sComplexity = pass_Complexities[0]; }
    else if (nScore >= pass_ComplexityThresholds[0] && nScore < pass_ComplexityThresholds[1]) { sComplexity = pass_Complexities[1]; }
    else if (nScore >= pass_ComplexityThresholds[1] && nScore < pass_ComplexityThresholds[2]) { sComplexity = pass_Complexities[2]; }
    else if (nScore >= pass_ComplexityThresholds[2] && nScore < pass_ComplexityThresholds[3]) { sComplexity = pass_Complexities[3]; }
    else if (nScore >= pass_ComplexityThresholds[3] && nScore <= 100) { sComplexity = pass_Complexities[4]; }

    /* Display updated score criteria to client */
    oScore.css({ 'backgroundColor': pass_getBackgroundcolor(nScore) });
    if (pass_UseHelp) { oHelp.html(sStandard); }
    switch (pass_DisplayType) {
        case 0:
            oScore.text(sComplexity + ' (' + nScore + "%)");
            break;

        case 1:
            oScore.text(sComplexity);
            break;

        case 2:
            oScore.text(nScore + "%");
            break;

        case 3:
            oScore.text('&nbsp;');
            break;
    }
    return isValid;
}
var rtSessionTimer = function () {
    var rtNow;
    var rtServerTime;
    var rtEnd;
    var rtWarningTimer = 60;
    var rtUIWarningTimer = 300;
    var rtSessionLength = 1200;
    var rtLoggedIn = false;
    var rtUseUIWarning = false;
    var rtTimeOffset = 0;
    var rtTime = 0;
    var rtRemaining = 0;
    var rtGetDataWait = 600;
    var rtUseTitle = false;
    var rtTitle = "";
    var rtColorBoxIsOpen = false;
    var rtControl = false;
    var rtTimerId;
    var rtLastLoggedInState = false;
    var rtDebugMode = 'N';

    if (typeof jQuery == 'undefined') return;

    $.get(rtGetLocation("S"), function (data) {
        rtNow = new Date().getTime();
        rtServerTime = rtNow;
        rtEnd = rtNow;
        rtProcessData(data);
        rtCalcWait();
        rtTitle = document.title;
        rtTimerId = window.setInterval(rtAction, 200);
        $("a.rtSessionContinue").click(function () { rtContinue(); });
        $("a.rtSessionLogout").click(function () { rtLogout(); });
        $("a.rtSessionContinue").attr("href", "javascript:void(0);");
        $("a.rtSessionLogout").attr("href", "javascript:void(0);");

    }, "html")

    function rtAction() {
        rtGetData();
        rtNow = new Date().getTime()
        rtTime = rtEnd - rtNow;
        rtRemaining = Math.floor((rtTime - rtTimeOffset) / 1000);
        rtUpdateDivs()
    }

    function rtGetData() {
        rtGetDataWait -= 1;
        if (rtGetDataWait > 0) { return }
        rtCalcWait();
        $.get(rtGetLocation("S"), function (data) { rtProcessData(data) }, "html");
    }

    function rtGetLocation(mode) {
        return "/RocktimeCore.Session.ashx?mode=" + mode + "&location=" + encodeURIComponent(window.location.pathname);
    }

    function rtCalcWait() {
        rtGetDataWait = ((rtEnd - rtNow) / 1000) - rtUIWarningTimer - 10;
        if (rtSessionLength < rtGetDataWait) { rtGetDataWait = rtSessionLength - 10; }
        if (rtLoggedIn && rtGetDataWait > 30) { rtGetDataWait = 30; }
        if (rtRemaining < rtUIWarningTimer + 20) { rtGetDataWait = 10; }
        if (rtRemaining < rtWarningTimer + 4) { rtGetDataWait = 2; }
        if (rtRemaining < 10) { rtGetDataWait = 1; }
        rtGetDataWait = Math.floor(rtGetDataWait * 5);
    }

    function rtProcessData(data) {
        var dataParts = data.split(",");
        rtServerTime = parseInt(dataParts[0]);
        rtEnd = parseInt(dataParts[1]);
        rtWarningTimer = parseInt(dataParts[2]);
        rtUIWarningTimer = parseInt(dataParts[3]);
        rtLoggedIn = (dataParts[4] == "True");
        rtUseUIWarning = (dataParts[5] == "True");
        rtUseTitle = (dataParts[6] == "True");
        rtSessionLength = parseInt(dataParts[7]);
        rtControl = (dataParts[8] == "True");
        rtDebugMode = dataParts[9];
        rtTimeOffset = rtServerTime - rtNow;
        if (rtLoggedIn) { rtLastLoggedInState = true; }
    }

    function rtUpdateDivs() {
        if (rtRemaining <= 0) {
            clearInterval(rtTimerId);
            if (rtLoggedIn | rtControl) { document.location = rtGetLocation("L"); }
            else { $.get(rtGetLocation("E")); }
        }
        //document.title = rtTitle + " (" + rtFormatTime(rtRemaining) + ")" + rtGetDataWait; //Ajax Call Timer Showing (Debug)
        if (rtUseTitle) { document.title = rtTitle + " (" + rtFormatTime(rtRemaining) + ")"; } //Normal Operation

        if (rtDebugMode == 'N' && !rtLoggedIn) {
            if (rtLastLoggedInState) {
                rtLastLoggedInState = false;
                rtLogout();
            }
            return;
        }
        rtSetTime("#rtUIDialogTime");
        rtShow("#rtUIDialog", (rtRemaining > rtWarningTimer) && rtDebugMode != 'P', true)

        if (!rtUseUIWarning || rtGetLocation == "C") { return; }
        rtSetTime("#rtUIWarningTime");
        rtShow("#rtUIWarning", (rtRemaining > rtUIWarningTimer) && rtDebugMode != 'B', false);
    }

    function rtShow(divId, divHide, useColorBox) {
        if ($(divId).length = 0) { return; }
        if (useColorBox) {
            if (divHide) { if (rtColorBoxIsOpen) { $.colorbox.close(); } }
            else {
                if (!rtColorBoxIsOpen) {
                    $.colorbox(
                        {
                            close: "",
                            escKey: false,
                            overlayClose: false,
                            inline: true,
                            href: divId,
                            onOpen: function () { rtColorBoxIsOpen = true; },
                            onCleanup: function () { rtContinue(); },
                            onClosed: function () { rtColorBoxIsOpen = false; }
                        }
                    );
                }
            }
        }
        else {
            if (divHide) { $(divId).hide(); }
            else { $(divId).show(); }
        }
    }

    function rtSetTime(divId) {
        if ($(divId).length = 0) { return; }
        $(divId).html(rtFormatTime(rtRemaining));
    }

    function rtFormatTime(timeSecs) {
        if (timeSecs < 0) return '0:00';
        return Math.floor(timeSecs / 60) + ':' + (timeSecs % 60).toFixed().pad(2, '0');
    }

    function rtContinue() {
        $.get(rtGetLocation("R"), function () { rtGetDataWait = -1; rtGetData(); });
    }

    function rtLogout() {
        rtRemaining = -1;
        rtUpdateDivs();
    }

    String.prototype.pad = function (l, s) {
        return (l -= this.length) > 0 ? (s = new Array(Math.ceil(l / s.length) + 1).join(s)).substr(0, s.length) + this + s.substr(0, l - s.length) : this;
    }
};
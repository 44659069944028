//
//Intercept and extend ValidatorUpdateDisplay
//
var ValidatorUpdateDisplaySaved;
document.addEventListener("DOMContentLoaded", function () {
    if (typeof (ValidatorUpdateDisplay) == "function") {
        ValidatorUpdateDisplaySaved = ValidatorUpdateDisplay;
        ValidatorUpdateDisplay = ValidatorUpdateDisplayEx;
    }
}
)

function ValidatorUpdateDisplayEx(val) {
    ValidatorUpdateDisplaySaved(val);
    RocktimeValidateExSetHighlight(getCoreValidator(val));
    if (!val.id.endsWith('_Core')) UpdateValidationSummariesAutoAction();
    return val.isvalid;
}

// 
//Core
//
function GetCoreIsValid(val) {
    if ((typeof (Page_Validators) != "undefined") && (Page_Validators != null)) {
        for (var i = 0; i < Page_Validators.length; i++) {
            if (val.controltovalidate == Page_Validators[i].controltovalidate && val.id != Page_Validators[i].id) {
                if (!Page_Validators[i].isvalid) { return false; }
            }
        }
    }
    return true;
}

function getCoreValidator(val) {
    if ((typeof (Page_Validators) != "undefined") && (Page_Validators != null)) {
        for (var i = 0; i < Page_Validators.length; i++) {
            if (val.controltovalidate == Page_Validators[i].controltovalidate && Page_Validators[i].id.endsWith('_Core')) return Page_Validators[i];
        }
    }
    return;
}

//
//Misc
//
function IsValidationGroupValid(validationGroup) {
    if (!validationGroup) validationGroup = '';
    if (typeof (Page_Validators) == "undefined") return true;
    for (var i = 0; i < Page_Validators.length; i++) {
        var val = Page_Validators[i];
        var valValidationGroup = val.validationGroup;
        if (!valValidationGroup) valValidationGroup = '';
        if ((typeof (val.enabled) == "undefined" || val.enabled != false) && valValidationGroup == validationGroup) {
            if (!val.id.endsWith('_Core')) {
                if (!val.isvalid) return false;
            }
        }
    }
    return true;
}

//
//Summary Error Highlighting
//
function UpdateValidationSummariesAutoAction() {
    if (typeof (Page_ValidationSummaries) == "undefined") return;
    var summary, sums, s;
    var headerSep, first, pre, post, end;
    for (sums = 0; sums < Page_ValidationSummaries.length; sums++) {
        summary = Page_ValidationSummaries[sums];
        if (!summary) continue;
        if (summary.AutoUpdateSummaryValidationMessages == 'true') {
            summary.style.display = "none";
            if (!IsValidationGroupValid(summary.validationGroup)) {
                var i;
                if (typeof (summary.showsummary) != "undefined") {
                    if (summary.showsummary.toLowerCase() != "false") {
                        summary.style.display = "";
                        if (typeof (summary.displaymode) != "string") {
                            summary.displaymode = "BulletList";
                        }
                        switch (summary.displaymode) {
                            case "List":
                                headerSep = "<br>";
                                first = "";
                                pre = "";
                                post = "<br>";
                                end = "";
                                break;
                            case "BulletList":
                            default:
                                headerSep = "";
                                first = "<ul>";
                                pre = "<li>";
                                post = "</li>";
                                end = "</ul>";
                                break;
                            case "SingleParagraph":
                                headerSep = " ";
                                first = "";
                                pre = "";
                                post = " ";
                                end = "<br>";
                                break;
                        }
                        s = "";
                        if (typeof (summary.headertext) == "string") {
                            s += summary.headertext + headerSep;
                        }
                        s += first;
                        for (i = 0; i < Page_Validators.length; i++) {
                            var summaryValidationGroup = summary.validationGroup;
                            if (!summaryValidationGroup) summaryValidationGroup = '';
                            var valValidationGroup = Page_Validators[i].validationGroup;
                            if (!valValidationGroup) valValidationGroup = '';
                            if (!Page_Validators[i].isvalid && typeof (Page_Validators[i].errormessage) == "string" && valValidationGroup == summaryValidationGroup) {
                                s += pre + Page_Validators[i].errormessage + post;
                            }
                        }
                        s += end;
                        summary.innerHTML = s;
                        window.scrollTo(0, 0);
                    }
                }
                if (typeof (summary.showmessagebox) != "undefined") {
                    if (summary.showmessagebox.toLowerCase() == "true") {
                        s = "";
                        if (typeof (summary.headertext) == "string") {
                            s += summary.headertext + "\r\n";
                        }
                        var lastValIndex = Page_Validators.length - 1;
                        for (i = 0; i <= lastValIndex; i++) {
                            var summaryValidationGroup = summary.validationGroup;
                            if (!summaryValidationGroup) summaryValidationGroup = '';
                            var valValidationGroup = Page_Validators[i].validationGroup;
                            if (!valValidationGroup) valValidationGroup = '';
                            if (!Page_Validators[i].isvalid && typeof (Page_Validators[i].errormessage) == "string" && valValidationGroup == summaryValidationGroup) {
                                switch (summary.displaymode) {
                                    case "List":
                                        s += Page_Validators[i].errormessage;
                                        if (i < lastValIndex) {
                                            s += "\r\n";
                                        }
                                        break;
                                    case "BulletList":
                                    default:
                                        s += "- " + Page_Validators[i].errormessage;
                                        if (i < lastValIndex) {
                                            s += "\r\n";
                                        }
                                        break;
                                    case "SingleParagraph":
                                        s += Page_Validators[i].errormessage + " ";
                                        break;
                                }
                            }
                        }
                        alert(s);
                    }
                }
            }
        }
    }
    UpdateSummaryWrappers();
}

//
//Field Error Highlighting
//
function RocktimeValidateExSetHighlight(val) {
    if (!val) return;
    if (GetCoreIsValid(val)) {
        RocktimeValidateClass(val, true);
        RocktimeValidateBorderCss(val, val.borderColorNormal);
        return;
    }
    RocktimeValidateClass(val, false);
    RocktimeValidateBorderCss(val, val.borderColorError);
}

function RocktimeValidateClass(val, removeClass) {
    if (val.fieldErrorClass == '') { return; }

    $('#' + val.fieldErrorClassTarget).removeClass(val.fieldErrorClass);
    if (!removeClass) { $('#' + val.fieldErrorClassTarget).addClass(val.fieldErrorClass); }

    if (val.fieldErrorClassCompareTarget != undefined && val.fieldErrorClassCompareTarget != '') {
        $('#' + val.fieldErrorClassCompareTarget).removeClass(val.fieldErrorClass);
        if (!removeClass && $('#' + val.controltocompare).val() != '') { $('#' + val.fieldErrorClassCompareTarget).addClass(val.fieldErrorClass); }
    }
    RocktimeValidateClassForMultiLine(val, true);
    if (!removeClass) { RocktimeValidateClassForMultiLine(val, false); }
}

function RocktimeValidateClassForMultiLine(val, removeClass) {
    RocktimeValidateClassForMultiLineAction($('#' + val.fieldErrorClassTarget).parent().parent().find(".rtRECView"), val, removeClass);
    RocktimeValidateClassForMultiLineAction($('#' + val.fieldErrorClassTarget).parent().parent().find(".rtTACView"), val, removeClass);
    RocktimeValidateClassForMultiLineAction($('#' + val.fieldErrorClassTarget).parent().parent().parent().find(".rtTACView"), val, removeClass);
}

function RocktimeValidateClassForMultiLineAction(target, val, removeClass) {
    if (target == null) { return };
    if (removeClass) {
        target.removeClass(val.fieldErrorClass);
    }
    else {
        target.addClass(val.fieldErrorClass);
    }
}

function RocktimeValidateBorderCss(val, borderColor) {
    if (borderColor == '') { return; }
    $('#' + val.fieldErrorClassTarget).css('border-color', borderColor);
    if (val.controltocompare != '') { $('#' + val.controltocompare).css('border-color', borderColor); }
}
